@use '../../../styles/colors';
@use '../../../styles/lengths';
@use '../../../styles/typography';
@use '../../../styles/radius';

.container {
  position: relative;
  display: grid;
  grid-template: 'content' 'footer';
  grid-template-rows: 1fr auto;
  width: 100%;
  height: calc(100vh - 150px - 10px);
  min-height: lengths.$chatMessagesMinHeight;
  background: colors.$white;

  .delay {
    animation: show-delay 1s linear;
  }

  @keyframes show-delay {
    0% {
      visibility: hidden;
    }

    99% {
      visibility: hidden;
    }

    100% {
      visibility: visible;
    }
  }

  .content {
    grid-area: content;
    padding-bottom: 80px;
  }

  .footer {
    grid-area: footer;
  }

  .none {
    height: 0;
    opacity: 0;
  }

  .fadeIn {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    animation-name: fade-in-anime;
    animation-duration: 0.5s;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }

  @keyframes fade-in-anime {
    from {
      opacity: 0;
      transform: translateY(72px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .fadeOut {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    animation-name: fade-out-anime;
    animation-duration: 0.5s;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }

  @keyframes fade-out-anime {
    0% {
      opacity: 1;
      transform: translateY(0);
    }

    100% {
      opacity: 0;
      transform: translateY(72px);
    }
  }

  .chatMessages {
    display: flex;
    flex-direction: column;
    gap: lengths.$chatMessagesGap;
    padding: 16px;
  }

  .toast {
    padding-bottom: 12px;
  }

  .userChoices {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .userTextMessage {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .reset {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px 0;
    background-color: colors.$darkBlue100;
  }

  .txtReset {
    margin-top: 0;
    margin-bottom: 0;
    font-family: typography.$fontFamily;
    font-size: typography.$fontSize14;
    font-weight: typography.$fontWeight400;
    line-height: typography.$lineHeight24;
    color: colors.$white;
    text-align: center;
  }
}
