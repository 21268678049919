@use 'src/styles/lengths';
@use 'src/styles/colors';
@use 'src/styles/typography';
@use 'src/styles/radius';

.container {
  padding: 16px 0;
  text-align: center;
  border: 1px solid colors.$gray20;
  border-radius: radius.$large;
  box-shadow: 0 2px 12px rgb(0 0 0 / 10%);

  .text {
    padding-bottom: 12px;
    font-family: typography.$fontFamily;
    font-size: typography.$fontSize16;
    font-weight: typography.$fontWeight500;
    color: colors.$darkBlue100;
  }

  .buttonContainer {
    display: flex;
    flex-direction: column;
    gap: lengths.$choiceButtonGap;
    align-items: center;
  }

  .choiceWrap {
    width: lengths.$choiceButtonWidth;
  }
}
